<template>
<v-container>
<v-card v-if="!preview">
      <v-card-title>
        <div class="d-flex align-center justify-space-between">
            
        Vorlage Bearbeiten: {{template.name}}
        <v-spacer></v-spacer>
            <v-btn icon size="small" color="red" v-on:click="unselect()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
          <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                    <v-stepper v-model="e1" vertical>
                        <v-stepper-header>
                            <template v-for="(config, index) in configs" :key="`${index + 1} - step`">
                                <v-stepper-item 
                                :complete="e1 > index +1"
                                :step="index + 1" 
                                :value="index + 1"
                                editable
                                :color="e1 === index + 1 ? 'primary' : 'grey'" 
                                @click.prevent=""
                                >
                                {{config.name}}
                                </v-stepper-item>
                                <v-divider v-if="index !== configs.length - 1"></v-divider>
                            </template>
                        </v-stepper-header>
                        <v-stepper-window>
                            <v-stepper-window-item 
                            v-for="(config, index) in configs"
                            :key="`${index + 1}-content`"
                            :value="index + 1"
                            >
                                <template v-if="index === 0">
                                    <v-row >
                                        <v-col>
                                            <v-text-field
                                                v-model="template.name"
                                                :counter="30"
                                                label="Name"
                                                required
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-textarea
                                                v-model="template.description"
                                                :counter="255"
                                                label="Beschreibung"
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                            v-model="template.type"
                                            :items="types"
                                            item-text="name"
                                            item-value="id"
                                            label="Typ"
                                            ></v-select>
                                        </v-col>
                                        <v-col></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-card flat>
                                                <v-card-title>Module</v-card-title>
                                                <v-card-text dense>
                                                    <v-list dense>
                                                        <v-list-item v-for="(module,index) in modules" :key=module.name>
                                                            <v-checkbox v-model="template.displayIntroduction" :label="module.name" v-if="module.id===0">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displayGeneralInformation" :label="module.name" v-if="module.id===1">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displaySkills" :label="module.name" v-if="module.id===2">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displayEducation" :label="module.name" v-if="module.id===3">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displayExperience" :label="module.name" v-if="module.id===4">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displayCertificates" :label="module.name" v-if="module.id===5">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displayHeader" :label="module.name" v-if="module.id===6">
                                                            </v-checkbox>
                                                            <v-checkbox v-model="template.displayProjects" :label="module.name" v-if="module.id===7">
                                                            </v-checkbox>
                                                            <v-spacer></v-spacer>
                                                            <!-- TODO order information not working yet. Display after fix-->
                                                            <v-btn icon @click="moduleUp(index)" v-if="index != 0 && false">
                                                                <v-icon>mdi-arrow-up</v-icon>
                                                            </v-btn>
                                                            <v-btn icon @click="moduleDown(index)" v-if="index != modules.length - 1 && false">
                                                                <v-icon>mdi-arrow-down</v-icon>
                                                            </v-btn>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-if="index === 1">
                                    <v-row>
                                        <v-col>
                                            <v-textarea v-model="template.template" label="Einführungstext" auto-grow>
                                            </v-textarea>
                                        </v-col>
                                        <v-col>
                                            <Markdown :source="template.template" />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-if="index === 2" >
                                        <template v-for="module in modules" :key="module.id">
                                            <v-row >
                                                <v-card outlined v-if="module.id === 6 && module.active" class="moduleConfigCard">
                                                    <v-card-title>
                                                        Header
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Layout
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-radio-group v-model="template.headerType">
                                                                            <v-radio :value="'HORIZONTAL'" :label="'Horizontal'"></v-radio>
                                                                            <v-radio :value="'VERTICAL'" :label="'Vertikal'"></v-radio>
                                                                        </v-radio-group>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Color
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-color-picker
                                                                        v-model="color"
                                                                        elevation="15"
                                                                        hide-mode-switch
                                                                        mode="hexa"
                                                                        ></v-color-picker>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col >
                                                                <v-card flat v-if="template.headerType === 'VERTICAL'">
                                                                    <v-card-title>
                                                                        Embed Modules
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-list dense>
                                                                            <v-list-item>
                                                                                <v-checkbox v-model="template.embedProjects" label="Projekte"></v-checkbox>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-checkbox v-model="template.embedExperience" label="Berufserfahrung"></v-checkbox>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-checkbox v-model="template.embedEducation" label="Bildung"></v-checkbox>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-checkbox v-model="template.embedCertificate" label="Zertifikate"></v-checkbox>
                                                                            </v-list-item>
                                                                        </v-list>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Logo
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-radio-group v-model="template.headerLogoType">
                                                                            <v-radio :value="'NONE'" :label="'Kein Logo'"></v-radio>
                                                                            <v-radio :value="'SMALL'" :label="'Kleines Logo'"></v-radio>
                                                                            <v-radio :value="'MEDIUM'" :label="'Mittleres Logo'"></v-radio>
                                                                            <v-radio :value="'LARGE'" :label="'Großes Logo'"></v-radio>
                                                                        </v-radio-group>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Profilbild
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-radio-group v-model="template.headerProfileType">
                                                                            <v-radio :value="'NONE'" :label="'Kein Profilbild'"></v-radio>
                                                                            <v-radio :value="'SMALL'" :label="'Kleines Profilbild'"></v-radio>
                                                                            <v-radio :value="'MEDIUM'" :label="'Mittleres Profilbild'"></v-radio>
                                                                            <v-radio :value="'LARGE'" :label="'Großes Profilbild'"></v-radio>
                                                                        </v-radio-group>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Profil Informationen
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-select :items="profileFields" v-model="template.headerFields" multiple chips label="Felder"
                                                                        item-text="name" item-value="key"
                                                                        >
                                                                        </v-select>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                                <v-card outlined v-if="module.id === 1 && module.active" class="moduleConfigCard">
                                                    <v-card-title>
                                                        Generelle Informationen
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Logo
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-radio-group v-model="template.logoType">
                                                                            <v-radio :value="'NONE'" :label="'Kein Logo'"></v-radio>
                                                                            <v-radio :value="'SMALL'" :label="'Kleines Logo'"></v-radio>
                                                                            <v-radio :value="'MEDIUM'" :label="'Mittleres Logo'"></v-radio>
                                                                            <v-radio :value="'LARGE'" :label="'Großes Logo'"></v-radio>
                                                                        </v-radio-group>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Profilbild
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-radio-group v-model="template.profileType">
                                                                            <v-radio :value="'NONE'" :label="'Kein Profilbild'"></v-radio>
                                                                            <v-radio :value="'SMALL'" :label="'Kleines Profilbild'"></v-radio>
                                                                            <v-radio :value="'MEDIUM'" :label="'Mittleres Profilbild'"></v-radio>
                                                                            <v-radio :value="'LARGE'" :label="'Großes Profilbild'"></v-radio>
                                                                        </v-radio-group>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card flat>
                                                                    <v-card-title>
                                                                        Profil Informationen
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <v-select :items="profileFields" v-model="template.fields" multiple chips label="Felder"
                                                                        item-text="name" item-value="key"
                                                                        >
                                                                        </v-select>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                                    <v-card outlined class="moduleConfigCard" v-if="module.id === 2 && module.active">
                                                        <v-card-title>
                                                            Skills
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-card flat class="moduleConfigCard">
                                                                        <v-card-title>
                                                                            Layout
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.skillLayout">
                                                                                <v-radio :value="'TABLE'" :label="'Tabellarisch'"></v-radio>
                                                                                <v-radio :value="'COMPACT'" :label="'Kompakt'"></v-radio>
                                                                                <v-radio :value="'HIERARCHY'" :label="'Hierarchisch'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card flat class="moduleConfigCard">
                                                                        <v-card-title>
                                                                            Darstellung
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.skillDisplay">
                                                                                <v-radio :value="'CHIPS'" :label="'Chips'"></v-radio>
                                                                                <v-radio :value="'TEXT'" :label="'Text'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card flat class="moduleConfigCard">
                                                                        <v-card-title>
                                                                            Levels
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.skillLevels">
                                                                                <v-radio :value="'NONE'" :label="'Keine'"></v-radio>
                                                                                <v-radio :value="'COLOR'" :label="'Farben'"></v-radio>
                                                                                <v-radio :value="'NUMBERS'" :label="'Zahlen'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                            
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card outlined v-if="module.id === 2 && module.active" class="moduleConfigCard">
                                                        <v-card-title>
                                                            Bildung
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Layout
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.educationLayout">
                                                                                <v-radio :value="'TABLE'" :label="'Tabellarisch'"></v-radio>
                                                                                <v-radio :value="'COMPACT'" :label="'Kompakt'"></v-radio>
                                                                                <v-radio :value="'TIMELINE'" :label="'Zeitlinie'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Beschreibung
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.educationDescription">
                                                                                <v-radio :value="'NONE'" :label="'Keine'"></v-radio>
                                                                                <v-radio :value="'TEXT'" :label="'Text'"></v-radio>
                                                                                <v-radio :value="'FORMATTED_TEXT'" :label="'Formatiert'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card outlined v-if="module.id === 3 && module.active" class="moduleConfigCard">
                                                        <v-card-title>
                                                            Berufserfahrung
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Layout
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.experienceLayout">
                                                                                <v-radio :value="'TABLE'" :label="'Tabellarisch'"></v-radio>
                                                                                <v-radio :value="'COMPACT'" :label="'Kompakt'"></v-radio>
                                                                                <v-radio :value="'TIMELINE'" :label="'Zeitlinie'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Beschreibung
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.experienceDescription">
                                                                                <v-radio :value="'NONE'" :label="'Keine'"></v-radio>
                                                                                <v-radio :value="'TEXT'" :label="'Text'"></v-radio>
                                                                                <v-radio :value="'FORMATTED_TEXT'" :label="'Formatiert'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card outlined v-if="module.id === 7 && module.active" class="moduleConfigCard">
                                                        <v-card-title>
                                                            Projekte
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Layout
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.projectsLayout">
                                                                                <v-radio :value="'TABLE'" :label="'Tabellarisch'"></v-radio>
                                                                                <v-radio :value="'COMPACT'" :label="'Kompakt'"></v-radio>
                                                                                <v-radio :value="'TIMELINE'" :label="'Zeitlinie'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Beschreibung
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.projectsDescription">
                                                                                <v-radio :value="'NONE'" :label="'Keine'"></v-radio>
                                                                                <v-radio :value="'TEXT'" :label="'Text'"></v-radio>
                                                                                <v-radio :value="'FORMATTED_TEXT'" :label="'Formatiert'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card outlined v-if="module.id === 4 && module.active" class="moduleConfigCard">
                                                        <v-card-title>
                                                            Zertifikate
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Layout
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.certificateLayout">
                                                                                <v-radio :value="'TABLE'" :label="'Tabellarisch'"></v-radio>
                                                                                <v-radio :value="'COMPACT'" :label="'Kompakt'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card flat>
                                                                        <v-card-title>
                                                                            Beschreibung
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-radio-group v-model="template.certificationDescription">
                                                                                <v-radio :value="'NONE'" :label="'Keine'"></v-radio>
                                                                                <v-radio :value="'TEXT'" :label="'Text'"></v-radio>
                                                                                <v-radio :value="'FORMATTED_TEXT'" :label="'Formatiert'"></v-radio>
                                                                            </v-radio-group>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                            </v-row>
                                        </template>
                                </template>
                            </v-stepper-window-item>
                        </v-stepper-window>
                    </v-stepper>
          </v-form>
      </v-card-text>
      
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" v-on:click="unselect()">Abbrechen</v-btn>
            <template v-if="e1 !== 3">
                <v-btn v-on:click="save()" >Speichern</v-btn>
                <v-btn color="primary" v-on:click="next()">Weiter</v-btn>
            </template>
            <template v-else>
                <v-btn v-on:click="togglePreview()" v-if="e1 === 3">Preview</v-btn>
                <!--<v-btn v-on:click="downloadPDF($event)" v-if="e1 === 3 && template.type==='PDF'">Download</v-btn>-->
                <v-btn v-on:click="save()" color="primary" v-if="e1 === 3">Speichern</v-btn>
            </template>
        </v-card-actions>
  </v-card>
  <TemplateView v-else :template="template" :expert="profile" :skills="skills" @depreview="updatePreview" :classifiedSkills="classifiedSkills"></TemplateView>
  
</v-container>
  
  
</template>
 
<script>
    import { Markdown } from 'vue3-markdown-it';
    import Vue from 'vue';
    import {createPDF} from '../../services/pdf.generation.service'
    import TemplateView from '../customItems/TemplateView'


  export default {
    name: 'Templates',
    data:() => ({
        valid : true,
        showSkills: true,
        generalInformation: true,
        experience: true,
        coverLetter: true,
        education: true,
        certificates: true,
        logoSelect: 0,
        profileSelect: 0,
        skillSelect: 0,
        educationSelect: 0,
        experienceSelect: 0,
        certificateSelect: 0,
        e1: 1,
        selectedFields: [],
        profileFields: [
            {title:"Name",key:"NAME"},
            {title:"Abschluss",key:"DEGREE"},
            {title:"Berufsbezeichnung",key:"POSITION"},
            {title:"Geburtsdatum",key:"BIRTHDAY"},
            {title:"Telefon",key:"PHONE"},
            {title:"Email",key:"EMAIL"},
            {title:"Adresse",key:"ADDRESS"}
        ],
        configs: [
            {name: "Konfiguration"},
            {name: "Einleitung"},
            {name: "Modul Konfiguration"}
        ],
        modules: [
            {id: 6, name: "Überschrift",active: true},
            {id: 0, name: "Einführungstext", active: true},
            {id: 1, name: "Generelle Informationen", active: true},
            {id: 2, name: "Skills", active: true},
            {id: 3, name: "Bildung",active: true},
            {id: 4, name: "Berufserfahrung", active: true},
            {id: 7, name: "Projekte", active: true},
            {id: 5, name: "Zertifikate", active: true},
            ],
        types: [{id:"ONLINE",title:"Link"},{id:"MAIL",title:"Mail"},{id:"PDF",title:"pdf"}],
        localPreview: false
    }),
    watch: {
        // Synchronisierung mit dem prop 'preview'
        preview(newVal) {
            this.localPreview = newVal;
        }
    },
    computed: {
        color: {
            get(){
                return this.template.color;
            },
            set(v){
                this.template.color = v;
                console.log(this.template.color);
            }
        }
    },
    props:{
        template : Object,
        sections : Object,
        profile : Object,
        skills: Array,
        classifiedSkills: Array,
        preview: Boolean
    },
    methods: {
        unselect(){
            this.$emit("unselect");
        },
        save(){
          this.template.modulesOrder = JSON.stringify(this.modules);
          this.$emit("save",this.template);
        },
        moduleUp(index){
            var temp = this.modules[index-1];
            Vue.set(this.modules,index-1,this.modules[index]);
            Vue.set(this.modules,index,temp);
        },
        moduleDown(index){
            var temp = this.modules[index+1];
            Vue.set(this.modules,index+1,this.modules[index]);
            Vue.set(this.modules,index,temp);
        },
        next(){
            if (this.e1 < this.configs.length) this.e1++;
        },
        downloadPDF (template) {
            createPDF(template,this.$refs.pdf,window);
        },
        togglePreview() {
            this.localPreview = !this.localPreview;
            this.$emit('update:preview', this.localPreview);
        },
        updatePreview(newVal) {
          this.localPreview = newVal;
          this.$emit('update:preview', this.localPreview);
        }
        
    },
    components: {
        Markdown,        
        TemplateView
    },
    created(){
        if (this.template.color && typeof this.template.color === 'object' && this.template.color.hex) {
            this.template.color = this.template.color.hex;
        } else if (!this.template.color) {
            this.template.color = '#000000';
        }
        if(this.template.modulesOrder) {
          this.modules = JSON.parse(this.template.modulesOrder);
        }
        this.localPreview = this.preview;
    },
    filters: {
      insertVariables: function(value) {
        //var logo = "<img src=\"http://localhost:8080/Skilldb/img/logo_aqs.8dc25ba2.png\" alt=\"drawing\" width=\"400\"/>\n\n";
        //var dummy = {
        //skills : ["Java","Spring Boot","Netbeans","Eclipse","Intellij","Scrum","Kanban"]
        //}
        var skillText = "";
        //dummy.skills.forEach(element => {
        //    skillText+= this.sections.SKILLS.template.replace("$name",element)+"\n";
        //});
        var text =  value.replace("$Skills", skillText);
        return text;
      }
    }
  }
</script>

<style scoped>
.profile {
    width: 100%;
}
.moduleConfigCard {
    width: 100%;
}
</style>