import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import { createVuetify } from 'vuetify'
import 'vuetify/styles';
import './assets/css/style.css';
import { createI18n } from 'vue-i18n';
import VueHtmlToPaper from 'vue-html-to-paper';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Markdown from 'vue3-markdown-it';
import StartMasterPage from './views/StartMasterPage.vue';
import ProfilePage from './views/ProfilePage.vue';
import TemplatePage from './views/TemplatePage.vue';
import RolePage from './views/RolePage.vue';
import SearchPage from './views/SearchPage.vue';
import ConfigurationPage from './views/ConfigurationPage.vue';
import PublicationOverviewPage from './views/PublicationOverviewPage.vue';
import ComparePage from './views/ComparePage.vue';

const app = createApp(App);
const vuetify = createVuetify({
    components,
    directives,
  })

import en from './locales/en.json'
import de from './locales/de.json'

const i18n = createI18n({
  locale: 'de',
  messages: {
    en,
    de
  }
})

app
  .component('StartMasterPage', StartMasterPage)
  .component('ProfilePage', ProfilePage)
  .component('TemplatePage', TemplatePage)
  .component('RolePage', RolePage)
  .component('SearchPage', SearchPage)
  .component('ConfigurationPage', ConfigurationPage)
  .component('PublicationOverviewPage', PublicationOverviewPage)
  .component('ComparePage', ComparePage)

app.use(router);
app.use(store);
app.use(vuetify);
app.use(i18n);
app.use(VueHtmlToPaper);
app.use(Markdown);

app.mount('#app');
