import { createStore } from 'vuex';

import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './users.module';
import { general } from './general.module';
import { configuration } from './configuration.module';
import { searchExpert } from './searchExpert.module';
import { rolesAndPrivileges } from "./role.module";
import { template } from "./template.module";
import { publication } from "./publication.module";
import { meta } from "./meta.module";

export const store = createStore({
    modules: {
        alert,
        account,
        users,
        rolesAndPrivileges,
        general,
        configuration,
        searchExpert,
        template,
        publication,
        meta
    }
});
