<template>
<v-container class="content">
    <v-app-bar
      app
      color="white"
    >
      <v-spacer></v-spacer>
      <v-img src="../assets/logo_aqs.png" contain apsect-ration="1.7" max-height="64" max-width="207"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-container>
      <v-card class="mx-auto login">
        <v-card-title>
            <h2 v-if="tockenExists">{{$t('i18n_reset_password')}}</h2>
            <h2 v-if="!tockenExists">{{$t('i18n_tocken_expired')}}</h2>
        </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                v-if="tockenExists"
              >
                <v-col cols="12">
                  <v-text-field 
                    :label="$t('i18n_new_password')"
                    v-model="newPassword.newPassword"
                    :rules="passwordRules"
                    @change="validate"
                    type="password"
                    required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t('i18n_repeat_password')"
                    v-model="newPassword.repeatPassword"
                    :rules="passwordRules"
                    @change="validate"
                    type="password"
                    required>
                  </v-text-field>
                </v-col>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="tockenExists">
              <v-container>
                <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="onSubmit">
                    {{$t('i18n_reset_password')}}
                </v-btn>
              </v-container>
            </v-card-actions>
      </v-card>
    </v-container>
</v-container>
    
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    data () {
        return {
          valid: true,
          passwordRules:[
              v => !!v || 'Passwort erforderlich',
              () => (this.newPassword.newPassword === this.repeatPassword) || (this.newPassword.repeatPassword === this.newPassword.newPassword) || 'Passwort muss übereinstimmen',

          ],
          newPassword: {
            tocken:"",
            newPassword:"",
            repeatPassword:""
          }
        }
    },
    computed: {
        ...mapState({
          tockenExists: state=> state.account.tockenExists
      }),
    },
    mounted() {
      this.checkTocken(window.location.pathname.split('/')[2]);
    },
    methods: {
        ...mapActions('account', ['resetPassword', 'checkTocken']),
        onSubmit () {
          this.newPassword.tocken= window.location.pathname.split('/')[2];
          this.resetPassword(this.newPassword)
        },
        validate () {
          this.$refs.form.validate()
        },
    }
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
}

.login{
  width: 55%;
  margin-top: 5%;
}
.container {
  align-content: center;
}
</style>
