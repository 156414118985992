<template>
  <v-list dense subheader>
    <v-col v-if="myClassifiedSkills.userSkills.length !== 0
    || myClassifiedSkills.offsprings.length !== 0">
      <ChipsWithTooltips :items="sortSkillsByLevel(this.myClassifiedSkills.userSkills)"></ChipsWithTooltips>
      <div class="subcontent2" v-for="subItem in myClassifiedSkills.offsprings"  v-bind:key="subItem.classification">
        <CustomSkillTree
          :title="subItem.name"
          :visible="hasChildOnLeaf(subItem)"
          v-on:onAdd="addSkills(subItem)"
          class="subcontent">
          <Skills :myClassifiedSkills="subItem" @fire-addSkills="addSkills"/>
        </CustomSkillTree>
      </div>
    </v-col>
  </v-list>
</template>
 
<script>
  import CustomSkillTree from './CustomSkillTree.vue'
  import ChipsWithTooltips from "./ChipsWithTooltips";
  import { sortSkillsByLevel } from "../../utils/profilePage.utils";

  export default {
    name: 'Skills',
    components: {
      CustomSkillTree,
      ChipsWithTooltips
    },
    props: {
      myClassifiedSkills: Object,
    },
    methods: {
      addSkills(subItem) {
        this.$emit("fire-addSkills", subItem);
      },
      hasChildOnLeaf(item){
        if(Array.isArray(item.offsprings) && item.offsprings.length !== 0){
          return this.hasChildOnLeaf(item.offsprings[0]);
        } else {
          return Array.isArray(item.userSkills) && item.userSkills.length !== 0;
        }
      },
      sortSkillsByLevel
    }
  }
</script>

<style>
    .chipSeparation {
      margin-right: 5px;
      margin-bottom: 5px;
    }
    /* .v-list-item__title {

      } */

      .v-list {
      margin-top : 14px
      }
      .subcontent2 {
      margin-bottom  : 14px;
      }

</style>
