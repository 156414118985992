<template>
    <v-card class="profile" flat>
        <v-card-title>
            Zertifikate
        </v-card-title>
        <v-card-text>
            <v-simple-table v-if="table">
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="certificate in certificates" :key="certificate.id" class="print-section">
                                    <td>{{certificate.name}}</td>
                                    <td>{{validDate(certificate)}}</td>
                                    <td v-if="description">
                                        <template v-if="formatted">
                                            <Markdown :source="certificate.description" />
                                        </template>
                                        <template v-else>
                                            {{certificate.description}}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-list dense v-else>
                        <template v-for="(item, index) in certificates" :key="item.skill">
                            <v-list-item class="showhim print-section"
                                >
                                <v-list-item-icon>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                <v-list-item-subtitle class="text--primary">{{validDate(item)}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                                v-if="index + 1 < certificates.length"
                                :key="index"
                            ></v-divider>
                            </template>
                    </v-list>
        </v-card-text>

    </v-card>
</template>
 
<script>
    //import { mapState } from "vuex";
    import { Markdown } from 'vue3-markdown-it';
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object
        },
        computed: {
            certificates(){
                return this.expert.certificates
            },
            table(){
                return this.template.certificateLayout === 'TABLE';
            },
            compact(){
                 return this.template.certificateLayout === 'COMPACT';
            },
            description(){
                return this.template.certificationDescription !== 'NONE';
            },
            formatted(){
                return this.template.certificationDescription === 'FORMATTED_TEXT'
            }
        },
        methods : {
            validDate(item) {
            if (item.dateIssued) {
                const date = new Date(item.dateIssued);
                return `${date.getMonth() + 1}/${date.getUTCFullYear()}`;
            }
            return '';
        }
        },
        components : {
            Markdown
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
}   
</style>
