<template>
  <v-container class="view" centered>
    <div class="text-center" v-if="loading">
        <v-progress-circular
                size="100"
                indeterminate>
        </v-progress-circular>
    </div>
    <v-card class="view">
        <v-card-title>
            Freigaben
        </v-card-title>
        <v-card-text>
            <v-data-table fixed-header>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Profile</th>
                            <th>Template</th>
                            <th>Due Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="publication in publications" :key="publication.id">
                            <td>{{publication.id}}</td>
                            <td>{{publication.user.firstname}} {{publication.user.lastname}} </td>
                            <td> {{publication.template.name}} </td>
                            <td> {{publication.dueDate}} </td>
                            <td> 
                              <v-icon @click="$router.push({name:'publication', params: {token: publication.token}})" >mdi-file-eye</v-icon>
                              <v-icon class="delete-button" color="red lighten-1" @click="deleteData(publication.id)" >mdi-delete</v-icon> 
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="310"
      persistent >
      <v-card>
        <v-card-title >{{$t(`profil.${this.lang}.msg_delete_warning`)}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            
            @click="dialog = false"
          >
            {{$t(`profil.${this.lang}.no`)}}
          </v-btn>

          <v-btn
            color="primary"
            
            @click="sendDelete()"
          >
            {{$t(`profil.${this.lang}.yes`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>     
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data: () => ({
        dialog:false,
        selectedData:0,
        loading: true
    }),
    computed : {
        ...mapState({
            publications: state => state.publication.publications,
            lang: state => state.general.profilLang,
            
        })
    },
    methods: {
      ...mapActions('publication', ['listPublication', 'delete']),
      deleteData(id) {
      this.selectedData = id;
      this.dialog = !this.dialog;
      },
      sendDelete() {
        this.delete({id : this.selectedData});
        this.dialog = !this.dialog;
      },
    },
    created() {
        this.listPublication();
    },
    watch: {
      publications: {
          deep: true,
          handler: function(){
              this.loading = false;
          }
      }
  }
}
</script>
<style scoped>
.view {
    max-width: 1000px;
}
.delete-button {
    margin-left: 15px;
}
</style>