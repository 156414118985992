import { createRouter, createWebHistory } from 'vue-router';
import StartMasterPage from "../views/StartMasterPage.vue";
import LoginPage from "../views/LoginPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import RetrievePasswordPage from "../views/RetrievePasswordPage.vue";
import PublicationPage from "../views/PublicationPage.vue"
import ResetPasswordPage from "../views/ResetPasswordPage.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/home", component: StartMasterPage },
    { path: "/login", component: LoginPage },
    { path: "/register", component: RegisterPage },
    { path: "/retrievePassword", component: RetrievePasswordPage },
    { path: "/resetPassword/:id", component: ResetPasswordPage },
    { path: "/publication/:token", component: PublicationPage, name: "publication"},

    // otherwise redirect to home
    { path: "/:pathMatch(.*)*", redirect: "/home" }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/register", "/retrievePassword"];
  const authRequired = !publicPages.includes(to.path) && !to.path.includes("/resetPassword/") && !to.path.match("/publication/*");
  //
  const loggedIn = localStorage.getItem("user");
  if (authRequired && !loggedIn ) {
    return next("/login");
  }
  next();
  
});

export function hostUrl() {
  //return "http://85.214.20.168:8081"
  var host = window.location.hostname;
  var backendUri = "http://"+host+":" + process.env.VUE_APP_ENV_PORT;
  if(host.indexOf("profilmanager.aequitas-software.de") !== -1) {
    backendUri = "https://"+host;
  }
  return backendUri + "/db";
}
