<template>
   <div>
      <v-data-table
        :headers="tableHeaders"
        :items="roles"
        :search="search"
        item-key="id"
        :loading="loading"
        style="margin-top: 10px"
        >
         <template v-slot:top>
            <v-toolbar flat color="white">
                  <v-toolbar-title>{{$t('i18n_master_roles')}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                     v-model="search"
                     append-icon="mdi-magnify"
                     :label="$t('i18n_search')"
                     single-line
                     hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <DeleteWarning :dialog="deleteDialogOn" @fire-no="cancelDelete" @fire-yes="sendDelete"/>
                  <EditRole :toEdit="newRole" :dialog="editDialogOn" @fire-save="save" @fire-close="initEdit"/>
            </v-toolbar>
         </template>
         <template v-slot:item.privileges="{ item }">
            <div v-for="privilege in allowedPrivileges" :key="privilege.name">
               <v-list-item-subtitle>
                  <v-row>
                     <v-col cols="6" class="no-top-and-bottom-padding">
                        <b>{{privilege.name}} </b>
                     </v-col>
                     <v-col cols="6" class="no-top-and-bottom-padding">
                        <v-icon color="green lighten-1" v-if="roleHasPrivilege(privilege.name, item)">
                           mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                     </v-col>
                  </v-row>
               </v-list-item-subtitle>
            </div>
         </template>
         <template v-slot:item.action="{ item }" v-show="false">
            <v-icon
              class="mr-2"
              @click="editItem(item)">
               mdi-pen
            </v-icon>
            <v-icon
              @click="deleteItem(item)">
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";

    import EditRole from '../customDialogs/EditRole.vue';
    import DeleteWarning from '../customDialogs/DeleteWarning.vue';

    export default {
        data() {
           return {
              roleFormRules: [
                 v => !!v || 'Das ist ein Pflichtfeld, füllen Sie es bitte aus.'
            ],
            editDialogOn: false,
            deleteDialogOn: false,
            currentRole: null,
            search:"",
            newRole: {
               id: null,
               name: '',
               privileges: []
            }
           }
        },
        computed: {
            ...mapState({
                loading: state => state.rolesAndPrivileges.all.loading,
                roles: state => state.rolesAndPrivileges.roles,
                allowedPrivileges: state => state.rolesAndPrivileges.privileges,
                isEditable: state => state.general.selectedPageEditable
            }),
            tableHeaders() {
              var result = [
                { title: this.$t('i18n_role'), value: 'name', sortable: true },
                { title: this.$t('i18n_rights'), value: 'privileges', sortable: true }
              ]
              if(this.isEditable) {
                result.push({ title: this.$t('i18n_actions'), value: 'action', sortable: false });
              }
              return result;
            }
        },
        methods: {
            ...mapActions('rolesAndPrivileges', ['saveRole', 'deleteRole']),
            roleHasPrivilege(requestPrivilegeName, role) {
              if (role.privileges){
                for(let i = 0; i < role.privileges.length; i++){
                  if(requestPrivilegeName === role.privileges[i]) {
                    return true;
                  }
                }
              }
              return false;
            },
            save(toSave) {
                this.saveRole({newRole : toSave});
                this.initEdit(false);
            },
            editItem (item) {
                this.editDialogOn = true;
                this.newRole = Object.assign({}, item);
            },
            deleteItem (item) {
                this.currentRole = item.id;
                this.deleteDialogOn = true;
            },
            sendDelete() {
                this.deleteRole(this.currentRole);
                this.deleteDialogOn = false;
            },
            cancelDelete() {
                this.currentItem = null;
                this.deleteDialogOn = false;
            },
            initEdit(isOpen){
                this.newRole = {
                    id: null,
                    name: '',
                    privileges: []
                };
                this.editDialogOn = isOpen;
            }
        },
        components: {
            EditRole,
            DeleteWarning
        }
    }
</script>

<style>
   .no-top-and-bottom-padding {
      padding-bottom: 0;
      padding-top: 0;
   }
</style>
