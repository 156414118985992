<template>
  <v-container>
    <div class="text-center" v-if="loading">
      <v-progress-circular
              size="100"
              indeterminate>
      </v-progress-circular>
    </div>
    <v-card class="view">
      <v-card-title>
        <h3>{{$t('i18n_master_config')}}</h3>
      </v-card-title>
      <v-card-text>
         <v-container>
        <v-tabs
          v-model="currentItem"
          fixed-tabs
          bg-color="#3f51b5"
          class="custom-tabs"
          >
          <v-tab color="white">
            {{$t('i18n_conf_skills')}}
          </v-tab>
          <v-tab color="white">
            {{$t('i18n_languages')}}
          </v-tab>
          <v-tab color="white">
            {{$t('i18n_conf_categories')}}
          </v-tab>
          <v-tab color="white">
            {{$t('i18n_conf_ratings')}}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
          <v-tab-item key="skills">
            <TabConfigSkills v-if="currentItem === 0"/>
          </v-tab-item>

          <v-tab-item key="languages">
            <TabConfigLanguages v-if="currentItem === 1"/>
          </v-tab-item>

          <v-tab-item key="categories">
            <TabConfigClassifications v-if="currentItem === 2"/>
          </v-tab-item>

          <v-tab-item key="ratings">
            <TabConfigRatings v-if="currentItem === 3"/>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      </v-card-text>
    </v-card>
     
      
  </v-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  import TabConfigSkills from '../components/tabs/TabConfigSkills.vue'
  import TabConfigLanguages from '../components/tabs/TabConfigLanguages.vue'
  import TabConfigClassifications from '../components/tabs/TabConfigClassifications.vue'
  import TabConfigRatings from '../components/tabs/TabConfigRatings.vue'

  export default {
    data: () => ({
      currentItem: 0,
    }),
    computed: {
      ...mapState({
        renderTable: state => state.configuration.renderTable,
        loading: state => state.configuration.loading
      }),
    },
    created() {
      this.getConfiguration(this.$i18n.locale);
    },
    methods: {
      ...mapActions('configuration', ['getConfiguration']),
    },
    components: {
      TabConfigSkills,
      TabConfigLanguages,
      TabConfigClassifications,
      TabConfigRatings
    }
  }
</script>
<style scoped>
  .view {
      width:90%;
      height:90%;
    }
  .custom-tabs .v-tab {
    color: #b4b4b4;
  }
</style>