<template>
    <v-list dense>
      <template v-for="(item, index) in projects" :key="item.id">
        <v-list-item @mouseenter="hovering = true" @mouseleave="hovering = false">
        <v-container class="d-flex align-center">
            <v-list-item-icon>
            <v-icon style="transform: translateY(-9px);" class="mr-8">mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-text>
            <h3 style="margin-bottom: 5px;">{{ item.customer }}</h3>
            <v-list-item-subtitle>{{ item.branch }}</v-list-item-subtitle>
            <v-list-item-subtitle style="margin-bottom: 15px;">
              {{ formatDate(item.start) }} {{ item.end ? '-' : '' }} {{ formatDate(item.end) }}
            </v-list-item-subtitle>
            <div>
              <Markdown :source="item.description ? item.description : ''" />
            </div>
          </v-list-item-text>
          <v-list-item-action class="ml-auto" v-show="hovering">
            <v-row class="d-flex align-center">
              <v-btn icon elevation="0" size="small" @click="deleteData(item.id)">
                <v-icon color="red lighten-1">mdi-delete</v-icon>
              </v-btn>
              <v-btn icon elevation="0" size="small" @click="editData(item)">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
              <TranslateProject
                :dialog="dialogTranslate"
                :projectId="item.id"
                @closeTranslate="closeTranslate"
                @saveCloseTranslate="saveCloseTranslate"
              />
            </v-row>
          </v-list-item-action>
          </v-container>
        </v-list-item>
        <v-divider v-if="index + 1 < projects.length" :key="`${index}-divider`" />
      </template>
      <v-dialog v-model="dialog" max-width="310" persistent>
        <v-card>
          <v-card-title>{{ $t(`profil.${lang}.msg_delete_warning`) }}</v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" @click="dialog = false">
              {{ $t(`profil.${lang}.no`) }}
            </v-btn>
            <v-btn @click="sendDelete()">
              {{ $t(`profil.${lang}.yes`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { Markdown } from 'vue3-markdown-it';
  import TranslateProject from '../customDialogs/translate/TranslateProject.vue';
  
  export default {
    components: {
      Markdown,
      TranslateProject,
    },
    setup(props, {emit}) {
      const store = useStore();
      const dialog = ref(false);
      const dialogTranslate = ref(false);
      const selectedData = ref(0);
      const hovering = ref(false);
  
      const projects = computed(() =>
        store.state.users.projects.sort((a, b) => (new Date(a.end) - new Date(b.end)) * -1)
      );
      const isEditable = computed(() => store.state.general.selectedPageEditable);
      const lang = computed(() => store.state.general.profilLang);
  
      const formatDate = (date) => {
        return date ? `${new Date(date).getMonth() + 1}/${new Date(date).getUTCFullYear()}` : '';
      };
  
      const deleteData = (id) => {
        selectedData.value = id;
        dialog.value = true;
      };
  
      const sendDelete = () => {
        store.dispatch('users/deleteProject', { id: selectedData.value });
        dialog.value = false;
      };
  
      const editData = (item) => {
        emit('editProjects', item);
      };
  
      const closeTranslate = (bOpen) => {
        dialogTranslate.value = bOpen;
      };
  
      const saveCloseTranslate = (bOpen) => {
        store.dispatch('users/getUserById', { userId: store.state.account.user.userID, language: projects.value[0].code });
        dialogTranslate.value = bOpen;
      };
  
      return {
        projects,
        isEditable,
        lang,
        dialog,
        dialogTranslate,
        formatDate,
        deleteData,
        sendDelete,
        editData,
        closeTranslate,
        saveCloseTranslate,
        hovering,
      };
    },
  };
  </script>
  