<template>
   <v-container class="content">
      <v-app-bar
         app
         color="white"
         >
         <v-spacer></v-spacer>
         <v-img src="../assets/logo_aqs.png" contain apsect-ration="1.7" max-height="64" max-width="207"></v-img>
         <v-spacer></v-spacer>
         <span class="push">Version: {{ metaData.version }}</span>
         
      </v-app-bar>
      <v-container>
         <v-card class="mx-auto login">
            <v-card-title>
               <h2>{{$t('i18n_register_form')}}</h2>
            </v-card-title>
            <v-card-text>
               <v-form
                  ref="firstForm"
                  v-model="firstValid"
                  >
                  <v-text-field
                     v-model="user.firstname"
                     :rules="requiredRule"
                     :label="$t(`profil.${$i18n.locale}.firstname`) + '*'" 
                     required
                     ></v-text-field>
                  <v-text-field
                     v-model="user.lastname"
                     :rules="requiredRule"
                     :label="$t(`profil.${$i18n.locale}.lastname`) + '*'"
                     required
                     ></v-text-field>
                  <v-text-field
                     v-model="user.username"
                     :rules="requiredRule"
                     :label="$t('i18n_user_name') + '*'"
                     required
                     ></v-text-field>
                  <v-menu
                     v-model="menu"
                     :close-on-content-click="false"
                     :nudge-right="40"
                     transition="scale-transition"
                     offset-y
                     min-width="290px"
                     >
                     <template v-slot:activator="{ props }">
                        <v-text-field
                           v-model="user.birthday"
                           :label="$t(`profil.${$i18n.locale}.birthday`) + '*'"
                           :rules="requiredRule"
                           prepend-inner-icon="mdi-calendar"
                           readonly
                           v-bind="props"
                           required
                           ></v-text-field>
                     </template>
                     <v-date-picker v-model="user.birthday" @input="menu = false" :max="today"></v-date-picker>
                  </v-menu>
                  <v-text-field
                     v-model="emailPreffix"
                     prepend-inner-icon="mdi-email"
                     :rules="requiredRule"
                     :suffix="emailSuffix"
                     :label="$t(`profil.${$i18n.locale}.email`) + '*'"
                     required
                     ></v-text-field>
                  <v-text-field
                     v-model="user.phone"
                     prepend-inner-icon="mdi-phone"
                     type="number"
                     :label="$t(`profil.${$i18n.locale}.telefon`)"
                     ></v-text-field>
                  <v-text-field
                     v-model="user.position"
                     prepend-inner-icon="mdi-briefcase"
                     :label="$t(`profil.${$i18n.locale}.position`)"
                     ></v-text-field>
                  <v-text-field
                     v-model="user.address"
                     prepend-inner-icon="mdi-map-marker"
                     :label="$t(`profil.${$i18n.locale}.adress`)"
                     ></v-text-field>
               </v-form>
               <v-form
                  ref="secondForm"
                  v-model="secondValid"
                  >
                  <v-text-field
                     v-model="user.password"
                     :rules="passwordRules"
                     prepend-inner-icon="mdi-key"
                     :label="$t('i18n_password') + '*'"
                     type="password"
                     @change="validate"
                     required
                     ></v-text-field>
                  <v-text-field
                     v-model="repeatPassword"
                     :rules="passwordRules"
                     prepend-inner-icon="mdi-key-plus"
                     :label="$t('i18n_repeat_password') + '*'"
                     type="password"
                     @change="validate"
                     required
                     ></v-text-field>
               </v-form>
            </v-card-text>
            <v-card-actions>
               <v-container>
                  <v-btn
                     :disabled="!firstValid || !secondValid"
                     color="success"
                     class="mr-4"
                     @click="onRegister"
                     >
                     {{$t('i18n_register')}}
                  </v-btn>
                  <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  <router-link to="/login" class="btn btn-link">{{$t('i18n_cancel')}}</router-link>
               </v-container>
            </v-card-actions>
         </v-card>
      </v-container>
   </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {today} from "../utils/date.utils";

export default {
    data () {
        return {
            emailSuffix:"@aequitas-software.de",
            emailPreffix:"",
            user: {
                firstname: '',
                lastname: '',
                brithday: new Date().toISOString().substr(0, 10),
                role: null,
                address: '',
                position: '',
                username: '',
                password: '',
                email:'',
                phone:''
            },
            menu: false,
            repeatPassword:'',
            requiredRule: [
                v => !!v || 'Feld erforderlich'
            ],
            passwordRules:[
                v => !!v || 'Passwort erforderlich',
                () => (this.user.password === this.repeatPassword) || (this.repeatPassword === this.user.password) || 'Passwort muss übereinstimmen',

            ],
            submitted: false,
            firstValid: true,
            secondValid: true
        }
    },
    computed: {
        ...mapState('account', ['status']),
        ...mapState({
         metaData: state => state.meta.meta}),
        today
    },
    methods: {
        ...mapActions('account', ['register']),
        ...mapActions('meta', ['getMetaData']),
        onRegister () {
            if(this.firstValid && this.secondValid) {
                this.user.email = this.emailPreffix + this.emailSuffix;
                this.register(this.user);
            }
        },
        validate () {
            this.$refs.secondForm.validate()
        }
    }
};
</script>

<style scoped>
.content {
   width: 100%;
   height: 100%;
}
.login{
    width: 55%;
    margin-top: 5%;
}
.container {
    align-content: center;
}
</style>
