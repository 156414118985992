<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent 
      width="400"
    >
      <v-card>
        <v-card-title primary-title>
          {{$t('i18n_change_password')}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-form
              ref="firstForm"
              v-model="firstValid"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t('i18n_old_password')"
                    v-model="newPassword.oldPassword"
                    type="password"
                    :rules="requiredRules">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t('i18n_new_password')"
                    v-model="newPassword.newPassword"
                    :rules="passwordRules"
                    @change="validate"
                    type="password"
                    required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t('i18n_repeat_password')"
                    v-model="newPassword.repeatPassword"
                    :rules="passwordRules"
                    @change="validate"
                    type="password"
                    required>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="closeDialog()"
          >
            {{$t('i18n_cancel')}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            @click="saveData()"
          >
            {{$t('i18n_save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    props: {
      dialog: Boolean
    },
    data () {
      return {
        firstValid: true,
        requiredRules: [
          v => !!v || "Field is required"
        ],
        passwordRules:[
                v => !!v || 'Passwort erforderlich',
                () => (this.newPassword.newPassword === this.repeatPassword) || (this.newPassword.repeatPassword === this.newPassword.newPassword) || 'Passwort muss übereinstimmen',

            ],
        newPassword: {
          oldPassword:"",
          newPassword:"",
          repeatPassword:""
        }
      }
    },
    methods: {
      ...mapActions('account', ['changePassword']),
      closeDialog() {
        this.init();
        this.$emit("close-dialog");
      },
      saveData() {
        this.changePassword(this.newPassword);
        this.init();
        this.$emit("close-dialog");
      },
      validate () {
          this.$refs.firstForm.validate()
      },
      init() {
        this.newPassword = {
          oldPassword:"",
          newPassword:"",
          repeatPassword:""
        }
      }
    }
  }
</script>