<template>
   <v-container>
    <v-row>
      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.name`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{basicData.firstname}} {{basicData.lastname}}
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.degree`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{basicData.degree}}
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.position`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{basicData.position}}
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.birthday`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{formatDate(basicData.birthday)}}
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.telefon`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{basicData.phone}}
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.email`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{basicData.email}}
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="5" class="d-flex align-center">
        <b>{{$t(`profil.${lang}.adress`)}}:</b>
      </v-col>
      <v-col cols="7" class="d-flex align-center">
        {{basicData.address}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import { mapState} from "vuex";

  export default {
    props: {
      expert:Object
    },
    computed:{
        ...mapState({
            basicData:state => state.users.myUser,
            lang: state => state.general.profilLang,
        })
    },
    methods: {
        formatDate(date) {
            if (date) {
                return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
            }
        }
    }
  }
</script>
<style scoped>
.v-col {
  padding: 7px; /* Setzt das Padding der Columns auf 0 */
  margin: 0; /* Setzt das Margin der Columns auf 0 */
}
</style>
