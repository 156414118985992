<template >
  <v-data-table
    :headers="roleUserHeader"
    :items="roleUser"
    :search="search"
    item-key="userId"
    style="margin-top: 10px"
    >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('i18n_user_roles')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('i18n_search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <EditRoleUser :toEdit="newRoleUser" :dialog="editDialogOn" @fire-save="save" @fire-close="initEdit"/>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pen
      </v-icon>
    </template>
    <template v-slot:item.roles="{  item }">
      <div v-for="(value, index) in item.roles" :key="value">
        <v-list-item-subtitle>{{index}}: {{value}}</v-list-item-subtitle>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions } from "vuex";

import EditRoleUser from '../customDialogs/EditRoleUser.vue';

export default {
    data: () => ({
        currentItem:null,
        editDialogOn:false,
        deleteDialogOn: false,
        search:"",
        newRoleUser: {
            id: null,
            userId: null,
            fullname: null,
            roleId: null
        }
    }),
    computed: {
        ...mapState({
            roleUser: state => state.rolesAndPrivileges.roleUser,
            isEditable: state => state.general.selectedPageEditable
        }),
        roleUserHeader() {
          var result = [
            { title: this.$t('i18n_user'), value: 'fullname', sortable: true},
            { title: this.$t('i18n_master_roles'), value: 'role.name', sortable: true }
          ]
          if(this.isEditable) {
            result.push({ title: this.$t('i18n_actions'), value: 'action', sortable: false });
          }
          return result;
        }
    },
    methods: {
        ...mapActions('rolesAndPrivileges', ['saveRoleUser']),
        save(toSave) {
            this.saveRoleUser({newRoleUser : toSave});
            this.initEdit(false);
        },
        editItem (item) {
            this.editDialogOn = true;
            this.newRoleUser.userId = item.userId;
            this.newRoleUser.fullname = item.fullname;
            this.newRoleUser.roleId = item.role.id;
        },
        initEdit(isOpen){
            this.newRoleUser = {
                userId: null,
                roleId: null
            };
            this.editDialogOn = isOpen;
        }
    },
    components: {
        EditRoleUser
    }
  }
</script>
