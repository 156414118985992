<template>
   <v-dialog :model-value="dialog" max-width="500px">
      <template v-slot:activator="{ }">
         <v-btn 
          color="primary" 
          dark 
          class="mb-2"
          v-show="isEditable"
          @click="close">{{$t('i18n_new')}}</v-btn>
      </template>
      <v-card>
         <v-card-title>
            <span class="headline">{{$t('i18n_edit_role')}}</span>
         </v-card-title>
         <v-card-text>
            <v-container>
               <v-form
                  ref="roleForm"
                  v-model="formValid">
                  <v-row>
                     <v-col cols="12">
                        <v-text-field
                           :label="$t('i18n_role_name')  + '*'"
                           :rules="roleFormRules"
                           v-model="toEdit.name">
                        </v-text-field>
                        <v-autocomplete
                           v-model="toEdit.privileges"
                           :items="allowedPrivileges"
                           item-title="name"
                           :label="$t('i18n_privilegien')"
                           chips
                           multiple
                           >
                        </v-autocomplete>
                     </v-col>
                  </v-row>
               </v-form>
            </v-container>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
            <v-btn color="primary" @click="save">{{$t('i18n_save')}}</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
   import { mapState } from "vuex";

   export default {
      data() {
         return {
            roleFormRules: [
                  v => !!v || "Field is required"
            ],
            formValid: true,
         }
      },
      props: {
         toEdit: Object,
         dialog: Boolean
      },
      computed: {
         ...mapState({
            loading: state => state.rolesAndPrivileges.all.loading,
            allowedPrivileges: state => state.rolesAndPrivileges.privileges,
            isEditable: state => state.general.selectedPageEditable
         })
      },
      methods: {
         close() {
            this.$emit("fire-close", !this.dialog);
         },
         save() {
            this.$refs.roleForm.validate()
            if(this.formValid) {
               this.$emit("fire-save", this.toEdit);
            }
         }
      }
   }
</script>

<style>
   .no-top-and-bottom-padding {
      padding-bottom: 0;
      padding-top: 0;
   }
</style>
