<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent 
      width="800"
      :retain-focus="false"
    >
      <template v-slot:activator="{ props }">
        <v-btn 
          v-show="isEditable"
          v-bind="props"
          elevation="0"
          icon
          @click="openDialog()">
          <v-icon color="indigo lighten-1">mdi-translate</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${lang}.basicdata`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
          <v-expansion-panels multiple >
            <v-expansion-panel  v-if="translations.de">
              <v-expansion-panel-title>DE</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form
                  ref="firstForm"
                  v-model="firstValid">
                  <v-row>
                      <v-col cols="12">
                        <v-text-field 
                          :label="$t(`profil.${lang}.position`)"
                          v-model="translations.de.position"
                        ></v-text-field>  
                      </v-col>
                      <v-col cols="12">
                        <v-text-field 
                          :label="$t(`profil.${lang}.degree`)"
                          v-model="translations.de.degree"
                        ></v-text-field>
                      </v-col>
                      <v-container>
                        <v-textarea
                          :label="$t(`profil.${lang}.summary`)"
                          v-model="translations.de.summary"
                        >
                        </v-textarea>
                      </v-container>
                  </v-row>
                </v-form>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel  v-if="translations.en">
              <v-expansion-panel-title>EN</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form
                  ref="firstForm"
                  v-model="firstValid">
                  <v-row>
                      <v-col cols="12">
                        <v-text-field 
                          :label="$t(`profil.${lang}.position`)"
                          v-model="translations.en.position"
                        ></v-text-field>  
                      </v-col>
                      <v-col cols="12">
                        <v-text-field 
                          :label="$t(`profil.${lang}.degree`)"
                          v-model="translations.en.degree"
                        ></v-text-field>
                      </v-col>
                      <v-container>
                        <v-textarea
                          :label="$t(`profil.${lang}.summary`)"
                          v-model="translations.en.summary"
                        >
                        </v-textarea>
                      </v-container>
                  </v-row>
                </v-form>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="closeDialog()"
          >
            {{$t('i18n_cancel')}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            @click="saveData()"
          >
            {{$t('i18n_save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  export default {
    props: {
      dialog: Boolean,
      userId: Number
    },
    data () {
      return {
        firstValid: true,
        requiredRules: [
          v => !!v || "Field is required"
        ],
      }
    },
    computed: {
      ...mapState({
        translations: state => state.configuration.texts,
        isEditable: state => state.general.selectedPageEditable,
        lang: state => state.general.profilLang
      }),
    },
    methods: {
      ...mapActions('configuration', ['saveTranslation', 'getTexts']),
      openDialog() {
        this.getTexts({tableName: "basicData", id: this.userId});
        this.$emit("closeTranslate", !this.dialog);
      },
      closeDialog() {
        this.$emit("closeTranslate", !this.dialog);
      },
      saveData() {
        this.saveTranslation(this.translations);
        this.$emit("closeTranslate", !this.dialog);
      }
    }
  }
</script>