<template>
  <v-container>
      <div class="text-center" v-if="loading">
        <v-progress-circular
                size="100"
                indeterminate>
        </v-progress-circular>
      </div>
      <v-card class="view">
        <v-card-title class="d-flex align-center">
          <h3>{{basicData.firstname}} {{basicData.lastname}}</h3>
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <small class="mr-2">DE</small>
            <v-switch v-model="profileLanguage" true-value="en" false-value="de"  style="transform: translateY(+9px);"></v-switch>
            <small class="ml-2">EN</small>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <profile-section>
                 <template v-slot:title>
                  <div class="d-flex align-center justify-space-between">
                    <v-avatar
                      align="center"
                      justify="center"
                      size="80"
                      color="grey"
                      tile
                      class="profile"
                      >
                        <v-img
                        :src="avatarImage">
                        </v-img>
                    </v-avatar>
                    <div class="d-flex align-center">
                      <EditBasicData :selectedData="selectedData" :dialog="expandBasicData" @update-expandBasicData="updateBasicData"/>
                      <TranslateBasicData :dialog="basicDataTranslate" :userId="basicData.userID" @closeTranslate="closeTranslate"/>
                    </div>
                  </div>
                 </template>
                 <template v-slot:content>
                  <user-info></user-info>
                 </template>
                </profile-section>
            </v-col>
            <v-col>
                <profile-section>
                  <template v-slot:title>
                      {{$t(`profil.${this.lang}.summary`)}}
                  </template>
                  <template v-slot:content>
                    <v-container >
                      <p class="summary">
                      {{basicData.summary}}
                      </p>
                    </v-container>
                  </template>
                </profile-section>
            </v-col>
            <v-col>
                <profile-section>
                  <template v-slot:title>
                  <div class="d-flex align-center justify-space-between">
                    {{$t(`profil.${this.lang}.languages`)}}
                    <v-btn 
                    icon
                    elevation="0"
                    v-on:click="addLanguages()">
                     <v-icon>{{ "mdi-plus-circle"}}</v-icon>
                   </v-btn>
                  </div>
                  
                </template>
                  <template v-slot:content>
                    <Languages @editLanguage="addLanguages"/>
                  </template>
                </profile-section>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <profile-section>
                  <template v-slot:title>
                  <div class="d-flex align-center justify-space-between">
                    {{$t(`profil.${this.lang}.education`)}}
                     <v-btn 
                      icon
                      elevation="0"
                      v-on:click="addEducation()">
                       <v-icon>{{ "mdi-plus-circle"}}</v-icon>
                    </v-btn>
                  </div>
                </template>
                  <template v-slot:content>
                    <Education @editEducation="editEducation"/>
                  </template>
                </profile-section>
            </v-col>
            <v-col>
                <profile-section>
                  <template v-slot:title>
                  <div class="d-flex align-center justify-space-between">
                    {{$t(`profil.${this.lang}.experience`)}}
                     <v-btn 
                      icon
                      elevation="0"
                      v-on:click="addExperience()">
                       <v-icon>{{ "mdi-plus-circle"}}</v-icon>
                    </v-btn>
                  </div>
                </template>
                  <template v-slot:content>
                    <Experience @editExperience="editExperience"/>
                  </template>
                </profile-section>
            </v-col>
            <v-col>
                <profile-section>
                <template v-slot:title>
                  <div class="d-flex align-center justify-space-between">
                    {{$t(`profil.${this.lang}.certificate`)}}
                     <v-btn 
                      icon
                      elevation="0"
                      v-on:click="addCertificates()">
                       <v-icon>{{ "mdi-plus-circle"}}</v-icon>
                    </v-btn>
                  </div>
                </template>
                  <template v-slot:content>
                    <Certificates @editCertificate="editCertificate"/>
                  </template>
                </profile-section>
            </v-col>
            <v-col>
              <profile-section>
                <template v-slot:title>
                  <div class="d-flex align-center justify-space-between">
                    {{$t(`profil.${this.lang}.projects`)}}
                     <v-btn 
                      icon
                      elevation="0"
                      v-on:click="addProjects()">
                       <v-icon>{{ "mdi-plus-circle"}}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:content>
                  <Projects @editProjects="editProjects"/>
                </template>
              </profile-section>
            </v-col>
            <v-col v-for="(section,index) in sections" :key="index" v-bind="section">
                <profile-section v-if="section.name === 'blank'">
                </profile-section>
                <profile-section v-else>
                  <template v-slot:title>
                    <div class="d-flex align-center justify-space-between">
                      {{section.name}}
                      <v-btn 
                        icon
                        elevation="0"
                        v-on:click="addSkills(section)">
                        <v-icon>{{ "mdi-plus-circle"}}</v-icon>
                      </v-btn>
                    </div></template>
                  <template v-slot:content>
                    <Skills :myClassifiedSkills="section" @fire-addSkills="addSkills"/>
                  </template>
                </profile-section>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    <EditEducation v-if="expandEducation" :selectedData="selectedData" :dialog="expandEducation" @update-expandEducation="updateEducation"/>
    <EditExperience v-if="expandExperience" :selectedData="selectedData" :dialog="expandExperience" @update-expandExperience="updateExperience"/>
    <EditLanguages v-if="expandLanguages" :myLanguages="myLanguages" :dialog="expandLanguages" @update-expandLanguages="updateLanguages"/>
    <EditSkills v-if="expandSkills" :subClassification ="subClassification" :dialog="expandSkills" @update-expandSkills="updateSkills" />
    <EditProjects v-if="expandProjects" :selectedData="selectedData" :dialog="expandProjects" @update-expandProjects="updateProjects" />
    <EditCertificates v-if="expandCertificates" :selectedData="selectedData" :dialog="expandCertificates" @update-expandCertificates="updateCertificates"/>

  </v-container>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import Languages from '../components/customItems/Languages.vue';
  import EditLanguages from '../components/customDialogs/EditLanguages.vue';
  import EditEducation from '../components/customDialogs/EditEducation.vue';
  import Education from '../components/customItems/Education.vue';
  import Experience from '../components/customItems/Experience.vue';
  import EditExperience from '../components/customDialogs/EditExperience.vue';
  import Skills from '../components/customItems/Skills.vue';
  import EditSkills from '../components/customDialogs/EditSkills.vue';
  import Certificates from '../components/customItems/Certificates.vue';
  import EditCertificates from '../components/customDialogs/EditCertificates.vue';
  import EditBasicData from '../components/customDialogs/EditBasicData.vue';
  import ProfileSection from '../components/customItems/ProfileSection.vue';
  import EditProjects from '../components/customDialogs/EditProjects.vue';
  import Projects from '../components/customItems/Projects.vue';
  import UserInfo from '../components/customItems/UserInfo.vue';
  import TranslateBasicData from '../components/customDialogs/translate/TranslateBasicData.vue';

  export default {
    data: () => ({
      expandBasicData:false,
      expandEducation:false,
      expandExperience:false,
      expandLanguages:false,
      expandSkills:false,
      expandCertificates:false,
      expandProjects: false,
      basicDataTranslate:false,
      profileLanguage:"de",
      selectedData:{},
      subClassification:{},
      selectedSkills:[],
      availableSkills:[],
      skillTitle:"",
    }),
    components: {
      Education,
      Experience,
      Skills,
      ProfileSection,
      Certificates,
      Languages,
      UserInfo,
      EditBasicData,
      EditEducation,
      EditExperience,
      EditLanguages,
      EditSkills,
      EditCertificates,
      EditProjects,
      Projects,
      TranslateBasicData
    },
    filters: {
      getTitle(item) {
        return item[0].kategorie;
      },
      formatDate(date) {
        if(date) {
          return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
        }
      }
    },
    watch: {
      profileLanguage: function (val) {
        this.setProfilLang(val);
        this.getUserById({userId: this.me.userID, language: val });
      }
    },
    computed: {
      ...mapState({
        me: state => state.account.user,
        basicData:state => state.users.myUser,
        myClassifiedSkills: state => state.users.classifiedSkills,
        myLanguages: state => state.users.myLanguages,
        lang: state => state.general.profilLang,
        loading: state => state.users.all.loading,
        sections: state => {
          //console.log(state.users.classifiedSkills);
          var buffer = [];
          if(state.users.classifiedSkills) {
            state.users.classifiedSkills.forEach(function(element){
              buffer.push(element);
            })
          }
          return buffer;
        }
      }),
      educationIconChange: function() {
          return this.expandEducation ? 'mdi-chevron-up':'mdi-chevron-down';
      },
      avatarImage() {
        return this.basicData.image != null ? this.basicData.image : "";
      }
    },
    created() {
      this.profileLanguage = this.$i18n.locale
      if (this.me) {
        this.getUserById({userId: this.me.userID, language: this.$i18n.locale });
      }
      if(null == this.lang) {
        this.setProfilLang(this.$i18n.locale);
      }
    },
    methods: {
      ...mapActions('users', ['getUserById']),
      ...mapActions('general', ['setProfilLang']),
      addEducation() {
        this.expandEducation = !this.expandEducation
      },
      addExperience() {
        this.expandExperience = !this.expandExperience
      },
      addLanguages() {
        this.expandLanguages = !this.expandLanguages
      },
      addSkills(item) {
        this.subClassification = item;
        this.skillTitle = item.classification;
        this.selectedSkills = item.userSkills;
        this.availableSkills = item.classifiedSkills;
        this.expandSkills = !this.expandSkills;
      },
      addCertificates() {
        this.expandCertificates = !this.expandCertificates
      },
      addProjects(){
        this.expandProjects = !this.expandProjects;
      },
      updateBasicData(bOpen) {
        if(bOpen) {
          this.selectedData = Object.assign({}, this.basicData);
        } else {
          this.selectedData = {};
        }
        this.expandBasicData=bOpen;
      },
      updateEducation(bOpen) {
        this.selectedData = {};
        this.expandEducation=bOpen;
      },
      updateExperience(bOpen) {
        this.selectedData = {};
        this.expandExperience=bOpen;
      },
      updateLanguages(bOpen) {
        this.expandLanguages=bOpen;
      },
      updateProjects(bOpen){
        this.selectedData = {};
        this.expandProjects=bOpen;
      },
      updateSkills(bOpen) {
        this.expandSkills=bOpen;
      },
      updateCertificates(bOpen) {
        this.selectedData = {};
        this.expandCertificates=bOpen;
      },
      editEducation(item) {
        this.selectedData = item;
        this.expandEducation = !this.expandEducation;
      },
      editProjects(item){
        this.selectedData = item;
        this.expandProjects = !this.expandProjects;
      },
      editExperience(item) {
        this.selectedData = item;
        this.expandExperience = !this.expandExperience;
      },
      editCertificate(item) {
        this.selectedData = item;
        this.expandCertificates = !this.expandCertificates;
      },
      closeTranslate(bOpen) {
        this.basicDataTranslate = bOpen;
      }
    }
  }
</script>

<style>
  .content {
    max-width: 100%;
  }
  .title {
    padding-left: 12px;
  }
  .subcontent {
    padding-left: 12px;
    padding-right: 12px;
  }
  .view {
    width:90%;
    height:90%;
  }
  .gridCard{
    height: 520px;
    width: 100%;
    min-width: 360px;
    overflow-y: auto;
  }
  .gridContent{
        overflow-y: auto;
  }
  .summary{
    max-width: 350px;
  }
</style>
