<template>
  <v-list dense>
    <template v-for="(item, index) in studies" :key="item.school">
      <v-list-item  @mouseenter="hovering = true" @mouseleave="hovering = false">
        <v-container class="d-flex align-center">
        <v-list-item-icon>
          <v-icon class="mr-8">mdi-school</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <h3 style="margin-bottom: 5px;">{{item.school}}</h3>
          <v-list-item-subtitle>{{item.studieField}}</v-list-item-subtitle>
          <v-list-item-subtitle style="margin-bottom: 15px;">
            {{ formatDate(item.start) }} {{ item.end ? '-' : '' }} {{ formatDate(item.end) }}
          </v-list-item-subtitle>
          <div>
            <Markdown  :source="item.description ? item.description : ''" />
          </div>
        </v-list-item-content>
        <v-list-item-action class="ml-auto" v-show="hovering">
          <v-row class="d-flex align-center">
            <v-btn icon elevation="0" size="small" @click="deleteData(item.id)">
              <v-icon color="red lighten-1">mdi-delete</v-icon>
            </v-btn>
            <v-btn icon elevation="0" size="small" @click="editData(item)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <TranslateEducation :dialog="dialogTranslate" :educationId="item.id" @closeTranslate="closeTranslate" @saveCloseTranslate="saveCloseTranslate"/>
          </v-row>
        </v-list-item-action>
        </v-container>
      </v-list-item>
      <v-divider v-if="index + 1 < studies.length" :key="index"></v-divider>
    </template>
    <v-dialog v-model="dialog" max-width="310" persistent>
      <v-card>
        <v-card-title>{{$t(`profil.${this.lang}.msg_delete_warning`)}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">{{$t(`profil.${this.lang}.no`)}}</v-btn>
          <v-btn color="primary" @click="sendDelete()">{{$t(`profil.${this.lang}.yes`)}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Markdown } from 'vue3-markdown-it';
import TranslateEducation from '../customDialogs/translate/TranslateEducation.vue';

export default {
  components: {
    Markdown,
    TranslateEducation,
  },
  setup(props, {emit}) {
    const store = useStore();
    const dialog = ref(false);
    const dialogTranslate = ref(false);
    const selectedData = ref(0);
    const hovering = ref(false);

    const studies = computed(() => 
      store.state.users.studies.sort((a, b) => (new Date(a.end) - new Date(b.end)) * -1)
    );
    const me = computed(() => store.state.account.user);
    const isEditable = computed(() => store.state.general.selectedPageEditable);
    const lang = computed(() => store.state.general.profilLang);

    const formatDate = (date) => {
      if (date) {
        return new Date(date).getMonth() + 1 + '/' + new Date(date).getUTCFullYear();
      }
    };

    const deleteData = (id) => {
      selectedData.value = id;
      dialog.value = true;
    };

    const sendDelete = () => {
      store.dispatch('users/deleteEducation', { id: selectedData.value });
      dialog.value = false;
    };

    const editData = (item) => {
      emit('editEducation', item);
    };

    const closeTranslate = (bOpen) => {
      dialogTranslate.value = bOpen;
    };

    const saveCloseTranslate = (bOpen) => {
      store.dispatch('users/getUserById', { userId: me.value.userID, language: studies.value[0].code });
      dialogTranslate.value = bOpen;
    };

    return {
      studies,
      me,
      isEditable,
      lang,
      dialog,
      dialogTranslate,
      formatDate,
      deleteData,
      sendDelete,
      editData,
      closeTranslate,
      saveCloseTranslate,
      hovering,
    };
  },
};
</script>
<style>
.showme {
  display: none;
  flex-direction: row;
}

.showhim:hover .showme {
  display: flex;
  flex-direction: row;
}
</style>
