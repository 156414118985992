<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent 
      width="800"
    >
      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${this.lang}.projects`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-form v-model="firstValid">
              <v-row>
                <v-col cols="6">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.customer`) + '*'"
                    v-model="selectedData.customer"
                    :rules="requiredRules"
                    required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.role`)"
                    v-model="selectedData.role">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.branch`)"
                    v-model="selectedData.branch">
                  </v-text-field>
                </v-col>
                <v-row class="ml-3 mr-3">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition">
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            :modelValue="starting"
                            :label="$t(`profil.${lang}.start`) + '*'"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                            :rules="requiredRules"
                        ></v-text-field>
                    </template>
                    <v-date-picker @update:modelValue="updateStartDate" type="month" :max="today"></v-date-picker>
                  </v-menu>
                  <v-spacer/>
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition">
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-model="ending"
                            :label="$t(`profil.${lang}.end`)"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            @update:modelValue="updateEndDate"
                            type="month"
                            :min="selectedData.start"
                            :disabled="!starting"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    width="100%"
                    outlined
                    name="input-7-4"
                    :label="$t(`profil.${this.lang}.job_description`)"
                    v-model="selectedData.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>  
          </v-container>
          <small>{{'*' + $t(`profil.${this.lang}.required`)}}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            
            @click="closeDialog()"
          >
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            
            @click="saveData()"
          >
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import {today} from "../../utils/date.utils";

  export default {
    props: {
      dialog: Boolean,
      selectedData:Object
    },
    data () {
      return {
        menuStart:false,
        menuEnd:false,
        myDialog:false,
        firstValid: true,
        starting: null,
        ending: null,
        requiredRules: [
          v => !!v || "Field is required"
        ],
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        lang: state => state.general.profilLang,
      }),
      today,
    },
    methods: {
      ...mapActions('users', ['saveProjects']),
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("update-expandProjects", !this.dialog);
      },
      saveData() {
        this.selectedData.user = this.basicData;
        this.selectedData.user.id = this.basicData.userID;
        this.saveProjects(this.selectedData);
        this.myDialog = this.dialog;
        this.$emit("update-expandProjects", !this.dialog);
      },
      updateStartDate(newValue) {
       if(newValue instanceof Date && !isNaN(newValue.getTime())) {

        const date = new Date(newValue);
        const formatter = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedTime = formatter.format(date);
        this.selectedData.start = newValue;
        this.starting = formattedTime;

       }
       this.menuStart = false;
      },
      updateEndDate(newValue) {
        if(newValue instanceof Date && !isNaN(newValue.getTime())) {

          const date = new Date(newValue);
          const formatter = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
          const formattedTime = formatter.format(date);
          this.selectedData.end = newValue;
          this.ending = formattedTime;
        
        }
       this.menuEnd = false;
      }
    }
  }
</script>

<style>
  .date-row {
    margin-left: 12px;
    margin-right: 12px;
  }
</style>
