<template >
  <v-data-table
    :headers="classificationHeader"
    :items="classifications"
    :search="search"
    item-key="name"
    style="margin-top: 10px"
    >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('i18n_conf_categories')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('i18n_search')"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <DeleteWarning :dialog="deleteDialog" @fire-no="cancelDelete" @fire-yes="sendDelete"/>
        <TranslateClassification :dialog="translateDialog" @fire-close="closeTranslate"/>
        <EditConfigClassifications :toEdit="newClassification" :dialog="dialog" :selectedParent="selectedParent" @update:selectedParent="selectedParent = $event" @fire-save="save" @fire-close="initEdit"/>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pen
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        small
        @click="translateItem(item)"
      >
        mdi-translate
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import { constructClassificationHierarchy } from "../../utils/configurationPage.utils";

  import EditConfigClassifications from '../customDialogs/EditConfigClassifications.vue';
  import DeleteWarning from '../customDialogs/DeleteWarning.vue';
  import TranslateClassification from '../customDialogs/translate/TranslateClassification.vue';

  export default {
    data: () => ({
      currentItem: null,
      dialog: false,
      deleteDialog: false,
      translateDialog: false,
      search:"",
      selectedParent: undefined,
      editClassificationName: false,
      newClassification: {
        id: null,
        parent: null,
        code: "",
        layer:1,
        name: '',
      }
    }),
    computed: {
      ...mapState({
        classifications: state => state.configuration.classifications,
        isEditable: state => state.general.selectedPageEditable
      }),
      classificationsWithoutSelf() {
        if(null != this.newClassification.id) {
          return this.classifications.filter(p => p.id !== this.newClassification.id
                  && ((null == p.parent) || p.parent.id !== this.newClassification.id));
        }
        return this.classifications;
      },
      classificationHeader() {
        var result = [
          {
            title: this.$t('i18n_conf_category'),
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            title: this.$t('i18n_conf_layer'),
            value: 'layer',
            sortable: true
          },
          { title: this.$t('i18n_conf_parent_category'), value: 'parent.name', sortable: true }
        ];
        if(this.isEditable) {
          result.push({ title: this.$t('i18n_actions'), value: 'action', sortable: false });
        }
        return result;
      },
    },
    mounted() {
        this.newClassification.layer = 1;
    },
    methods: {
      ...mapActions('configuration', ['saveClassifications', 'deleteClassification', 'getTexts']),
      save(toSave) {
        this.saveClassifications(toSave);
        this.initEdit(false);
      },
      editItem (item) {
        this.dialog = true;
        this.newClassification = Object.assign({}, item);
        this.selectedParent = this.newClassification.parent;
        return item;
      },

      deleteItem (item) {
        this.currentItem = item;
        this.deleteDialog = true;
      },
      translateItem (item) {
        this.translateDialog = true;
        this.getTexts({tableName: "classification", id: item.id});
      },
      sendDelete() {
        this.deleteClassification(this.currentItem);
        this.deleteDialog = false;
      },
      cancelDelete() {
        this.currentItem = null;
        this.deleteDialog = false;
      },
      closeTranslate() {
        this.classificationId = null;
        this.translateDialog = false;
      },
      initEdit(isOpen) {
        this.newClassification = {
          id: null,
          parent: null,
          code: this.$i18n.locale,
          leaf: true,
          layer:1,
          name:"",
        };
        this.dialog = isOpen;
      },
      constructClassificationHierarchy
    },
    components: {
        EditConfigClassifications,
        DeleteWarning,
        TranslateClassification
    }
  }
</script>