<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent 
      width="800"
    >
      <v-card>
        <v-card-title primary-title>
          {{$t(`profil.${this.lang}.certificate`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-form v-model="firstValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.certificate_title`) + '*'"
                    v-model="selectedData.name"
                    required
                    :rules="requiredRules">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.issued_by`) + '*'"
                    v-model="selectedData.issued_by"
                    :rules="requiredRules">
                  </v-text-field>
                </v-col>
                <v-row class="ml-1">
                  <v-col cols="5">
                    <v-menu
                      v-model="menuEnd"
                      :close-on-content-click="false"
                      transition="scale-transition">
                      <template v-slot:activator="{ props }">
                          <v-text-field
                              v-model="date"
                              :label="$t(`profil.${lang}.valid_from`) + '*'"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="props"
                              :rules="requiredRules"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                              :modelValue="dateIssued"
                              @update:modelValue="selectDate"
                              type="month"
                              :max="today">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-form> 
          </v-container>
          <small>{{'*' + $t(`profil.${this.lang}.required`)}}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="closeDialog()"
          >
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            @click="saveData()"
          >
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import { today } from "../../utils/date.utils";

  export default {
    props: {
      dialog: Boolean,
      selectedData:Object
    },
    data () {
      return {
        menuStart:false,
        menuEnd:false,
        firstValid: true,
        date: null,
        requiredRules: [
          v => !!v || "Field is required"
        ],
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        lang: state => state.general.profilLang,
      }),
      today,
      dateIssued: {
        // getter
        // get: function () {
        //   let date = this.date;
        //   if (this.selectedData.dateIssued) {
        //     //date =  new Date(this.selectedData.dateIssued).toISOString().substr(0, 10);
        //   }
        //   return date;
        // },
        // // setter
        // set: function (newValue) {
        //   this.selectedData.dateIssued = new Date(newValue);
        //   this.date = newValue;
        // }
      },
    },
    methods: {
      ...mapActions('users', ['saveCertificate']),
      closeDialog() {
        this.$emit("update-expandCertificates", !this.dialog);
      },
      saveData() {
        this.selectedData.user = this.basicData;
        this.selectedData.user.id = this.basicData.userID;
        this.saveCertificate({newCertificate : this.selectedData});
        this.$emit("update-expandCertificates", !this.dialog);
      },
      selectDate(newValue) {
        if(newValue instanceof Date && !isNaN(newValue.getTime())) {

          const date = new Date(newValue);
          const formatter = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
          const formattedTime = formatter.format(date);
          this.selectedData.dateIssued = newValue;
          this.date = formattedTime;

        }   
        this.menuEnd = false;
      }
    }
  }
</script>
