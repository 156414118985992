const state = {
    visiblePages:[
      {
        id:0,
        name: "i18n_master_my_profil",
        icon: "mdi-account-tie",
        read: "readProfil",
        edit: "editProfil"
      },
      {
        id:1,
        name: "i18n_master_template",
        icon:"mdi-account-badge-horizontal-outline",
        read: "readTemplate",
        edit: "editTemplate"
      },
      {
        id:5,
        name: "Publications",
        read: "readTemplate",
        edit: "editTemplate"
      },
      /* TODO work on the compare page
      {
        id:6,
        name: "Compare",
        read: "readTemplate",
        edit: "editTemplate"
      },*/
      {
        id:2,
        name: "i18n_master_roles",
        icon:"mdi-police-badge",
        read: "readRole",
        edit: "editRole"
      },
      {
        id:3,
        name: "i18n_expert_search",
        icon:"mdi-account-search",
        read: "readExpert",
        edit: "editExpert"
      },
      {
        id:4,
        name: "i18n_master_config",
        icon:"mdi-toolbox",
        read: "readConfiguration",
        edit: "editConfiguration"
      }
    ],
    expandAll:{
      switch:false,
      expand:true,
    },
    relevanz: ['A','B','C','D'],
    langs: ['en', 'de'],
    selectedPageEditable: true,
    isPhone: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    profilLang: null
};

const actions = {
  onExpandAll({ commit }, input) {
    commit('onExpandAll', input);
  },
  setPageEditable({ commit }, isEditable) {
    commit('setPageEditable', isEditable);
  },
  setProfilLang({ commit }, lang) {
    commit('setProfilLang', lang);
  }
};

const mutations = {
  onExpandAll(state,input) {
    state.expandAll.switch = !state.expandAll.switch;
    state.expandAll.expand = input;
  },
  setPageEditable(state, isEditable) {
    state.selectedPageEditable = isEditable;
  },
  setProfilLang(state, lang) {
    state.profilLang = lang;
  }
};

export const general = {
  namespaced: true,
  state,
  actions,
  mutations
};
