<template>
  <v-container>
      <v-card class="view" v-if="!previewShare">
          <v-card-title>
              Share Expert: {{shareExpert.userDto.firstname}} {{shareExpert.userDto.lastname}}
              <v-spacer></v-spacer>
              <v-btn icon @click="deshare()">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
              <v-col>
                  <v-select
                  v-model="publication.template"
                  :items="templates"
                  :rules="isRequired"
                  :label="$t(`profil.${lang}.template`)"
                  item-text="name"
                  item-value="id"
                  ></v-select>
              </v-col>
              <v-col>
                  <v-autocomplete v-if="skills"
                    :label="$t(`profil.${lang}.expert_opt_skill`)"
                    v-model="publication.skills"
                    :items="skills"
                    item-text="name"
                    return-object
                    chips 
                    deletable-chips
                    multiple
                    clearable
                  >
                    <template v-slot:selection="data">
                      <v-menu offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            :input-value="data.selected"
                            close
                            @click:close="removePublicationSkill(data.item)"
                          >
                            {{ skillName(data.item.texts) }}
                          </v-chip>
                        </template>
                      </v-menu>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="selected({ data: data.item, selected: publication.skills })"
                            @change="selectSkill(data.item)"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ skillName(data.item.texts) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
              </v-col>
              </v-row>
              <v-row>
              <v-col>
                  <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="publication.dueDate"
                          :label="$t(`profil.${lang}.due_date`)"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker 
                      v-model="publication.dueDate"
                  >
                  </v-date-picker>
                  </v-menu>
              </v-col>
              <v-col>
                  <v-text-field
                  v-model="publication.password"
                  :label="$t(`profil.${lang}.password`)"
                  type="password"
                  counter
                  >

                  </v-text-field>
              </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="deshare()">{{$t(`profil.${this.lang}.cancel`)}}</v-btn>
              <v-btn :disabled="!valid" @click="previewShare=true">{{$t(`profil.${this.lang}.preview`)}}</v-btn>
              <v-btn 
                  :disabled="!valid" 
                  @click="save()" 
                  color="primary">
                  {{$t(`profil.${this.lang}.publish`)}}
              </v-btn>
          </v-card-actions>
      </v-card>
      <TemplateView v-else :template="selectedTemplate" :expert="retrievedExpert" :skills="simpleClassifiedSkills" @depreview="previewShare=false" :classifiedSkills="classifiedSkills"></TemplateView>
  </v-container>
</template>

<script>
import { computed, ref, onMounted } from 'vue' // Composition API imports
import { useStore } from 'vuex'
import i18n from '../../i18n'
import TemplateView from './TemplateView.vue'

export default {
  name: "Publish",
  props: {
      shareExpert: Object
  },
  setup(props, { emit }) {
      const store = useStore()
      const previewShare = ref(false)
      const publication = ref({
          skills: [],
          dueDate: "",
          template: "",
          password: ""
      })
      const isRequired = [v => !!v || 'Template erforderlich']
      const valid = ref(true)

      // Computed properties mapped from Vuex
      const templates = computed(() => store.state.template.all.items)
      const skills = computed(() => store.state.searchExpert.skills)
      const simpleClassifiedSkills = computed(() => store.state.users.simpleClassification)
      const classifiedSkills = computed(() => store.state.users.classifiedSkills)
      const retrievedExpert = computed(() => store.state.users.myProfile)
      const lang = computed(() => store.state.general.profilLang)

      const selectedTemplate = computed(() => {
          let ret = {}
          templates.value.forEach(element => {
              if (element.id === publication.value.template) {
                  ret = element
              }
          })
          return ret
      })

      // Methods (formerly inside "methods" object)
      const deshare = () => {
          emit('deshare')
      }

      const save = () => {
          publication.value.user = props.shareExpert.userDto.userID
          store.dispatch('publication/publish', publication.value)
          emit('deshare')
      }

      const selectSkill = (item) => {
          const index = publication.value.skills.indexOf(item)
          if (index < 0) {
              publication.value.skills.push(item)
          } else {
              publication.value.skills.splice(index, 1)
          }
      }

      const skillName = (texts) => {
          for (const index in texts) {
              if (texts[index].code === i18n.locale) {
                  return texts[index].name
              }
          }
          return "--"
      }

      const removePublicationSkill = (item) => {
          delete item.level
          publication.value.skills = publication.value.skills.filter(element => element.id !== item.id)
      }

      const selected = (data) => {
        if (data.data.level > 0 && data.selected.indexOf(data.data) < 0) {
            data.data.userId = data.userId;
            data.selected.push(data.data);
        }
        return data.selected.indexOf(data.data) > -1;
      };

      onMounted(() => {
          store.dispatch('template/getTemplates')
          store.dispatch('searchExpert/getSkills', i18n.locale)
      })

      return {
          previewShare,
          publication,
          isRequired,
          valid,
          templates,
          skills,
          simpleClassifiedSkills,
          classifiedSkills,
          retrievedExpert,
          lang,
          selectedTemplate,
          deshare,
          save,
          selectSkill,
          skillName,
          removePublicationSkill
      }
  },
  components: {
      TemplateView
  }
}
</script>

<style scoped>

</style>
