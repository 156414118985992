<template>
    <v-card class="profile test" flat>
        <v-card-title>
            Generelle Informationen
        </v-card-title>

        <v-list-item>
            <v-list-item-content>
                <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr v-if="template.fields.includes('NAME')">
                                <td><b>{{$t(`profil.${lang}.name`)}}:</b></td>
                                <td>{{expert.user.firstname}} {{expert.user.lastname}}
                                </td>
                            </tr>
                                <tr v-if="template.fields.includes('DEGREE')">
                                <td><b>{{$t(`profil.${lang}.degree`)}}:</b></td>
                                <td>{{expert.user.degree}}</td>
                            </tr>
                                <tr v-if="template.fields.includes('POSITION')">
                                <td><b>{{$t(`profil.${lang}.position`)}}:</b></td>
                                <td>{{expert.user.position}}</td>
                            </tr>
                                <tr v-if="template.fields.includes('BIRTHDAY')">
                                <td><b>{{$t(`profil.${lang}.birthday`)}}:</b></td>
                                <td>{{formatDate(expert.user.birthday)}}</td>
                            </tr>
                                <tr v-if="template.fields.includes('PHONE')">
                                <td><b>{{$t(`profil.${lang}.phone`)}}:</b></td>
                                <td>{{expert.user.phone}}</td>
                            </tr>
                                <tr v-if="template.fields.includes('EMAIL')">
                                <td><b>{{$t(`profil.${lang}.email`)}}:</b></td>
                                <td>{{expert.user.email}}</td>
                            </tr>
                                <tr v-if="template.fields.includes('ADDRESS')">
                                <td><b>{{$t(`profil.${lang}.adress`)}}:</b></td>
                                <td>{{expert.user.address}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
            </v-list-item-content>
            <v-list-item-avatar
                color="grey"
                :size="avatarSize"
                tile
                v-if="displayAvatar"
            >
                <v-img :src="expert.user.image"></v-img>
            </v-list-item-avatar>
        </v-list-item>        
    </v-card>
</template>
 
<script>
    import { mapState} from "vuex";
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object
        },
        
        computed: {
            ...mapState({
                
                lang: state => state.general.profilLang,
            }),
            displayAvatar(){
                return this.template.profileType !== 'NONE';
            },
            avatarSize(){
                if(this.template.profileType === 'NONE'){
                    return 0;
                }else if(this.template.profileType === 'SMALL'){
                    return 64;
                }else if(this.template.profileType === 'MEDIUM'){
                    return 128;
                }else if(this.template.profileType === 'LARGE'){
                    return 256;
                }else{
                    return 128;
                }
            }
        },
        methods : {
            formatDate(date) {
                if (date) {
                    const d = new Date(date);
                    return `${d.getDate()}/${d.getMonth() + 1}/${d.getUTCFullYear()}`;
                }
                return '';
            }
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
    
}
.profile .v-card__title{
    background-color: white;
}
.v-list-item {
    background-color: white;
}
</style>
