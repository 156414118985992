<template>
    <v-card class="profile" flat>
      <v-card-title>Bildung</v-card-title>
      <v-card-text>
        <v-simple-table v-if="isTableLayout">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Abschluss</th>
                <th>Fachbereich</th>
                <th>Institution</th>
                <th>Note</th>
                <th>Start</th>
                <th>Ende</th>
                <th v-if="hasDescription">Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="study in studies" :key="study.id" class="print-section">
                <td>{{ study.degree }}</td>
                <td>{{ study.studieField }}</td>
                <td>{{ study.school }}</td>
                <td>{{ formatDate(study.start) }}</td>
                <td>{{ formatDate(study.end) }}</td>
                <td v-if="hasDescription">
                  <template v-if="isFormatted">
                    <Markdown :source="study.description" />
                  </template>
                  <template v-else>
                    {{ study.description }}
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-container v-else-if="isTimelineLayout">
          <v-timeline dense>
            <v-timeline-item
              v-for="study in studies"
              :key="study.id"
              small
              :color="template.color"
              class="print-section"
            >
              <v-card>
                <v-card-title>
                  {{ study.degree }} {{ study.studieField }}
                </v-card-title>
                <v-card-subtitle>
                  {{ study.school }} {{ formatDate(study.start) }} - {{ formatDate(study.end) }}
                </v-card-subtitle>
                <v-card-text>
                  {{ study.description }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-container>
  
        <v-list dense v-else>
          <template v-for="(item, index) in studies" :key="item.school">
            <v-list-item class="showhim print-section">
              <v-list-item-icon>
                <v-icon>mdi-school</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <h3 style="margin-bottom: 5px;">{{ item.school }}</h3>
                <v-list-item-subtitle>{{ item.studieField }}</v-list-item-subtitle>
                <v-list-item-subtitle style="margin-bottom: 15px;">
                  {{ formatDate(item.start) }} {{ item.end ? '-' : '' }} {{ formatDate(item.end) }}
                </v-list-item-subtitle>
                <template v-if="hasDescription">
                  <div v-if="isFormatted">
                    <Markdown :source="item.description" />
                  </div>
                  <div v-else>
                    {{ item.description }}
                  </div>
                </template>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index + 1 < studies.length" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { Markdown } from 'vue3-markdown-it';
  
  export default {
    props: {
      expert: Object,
      template: Object,
    },
    components: {
      Markdown,
    },
    setup(props) {
      const studies = ref(props.expert.studies);
  
      const isTableLayout = computed(() => props.template.educationLayout === 'TABLE');
      const isTimelineLayout = computed(() => props.template.educationLayout === 'TIMELINE');
      const hasDescription = computed(() => props.template.educationDescription !== 'NONE');
      const isFormatted = computed(() => props.template.educationDescription === 'FORMATTED_TEXT');
  
      const formatDate = (date) => {
        if (date) {
          const formattedDate = new Date(date);
          return `${formattedDate.getDate()}/${formattedDate.getMonth() + 1}/${formattedDate.getUTCFullYear()}`;
        }
        return '';
      };
  
      return {
        studies,
        isTableLayout,
        isTimelineLayout,
        hasDescription,
        isFormatted,
        formatDate,
      };
    },
  };
  </script>
  
  <style scoped>
  .profile {
    width: 100%;
    page-break-inside: avoid;
    visibility: visible;
    height: auto !important;
    overflow-y: hidden;
  }
  .v-timeline-item {
    page-break-inside: avoid;
    visibility: visible;
    height: auto !important;
    overflow-y: hidden;
  }
  </style>
  